// Slider
// --------------------------------------------------

// Default controls
// -------------------------

.ui-slider {
  @extend .progress;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.ui-slider-handle {
  background-color: $slider-handle-bg;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 2;
  transition: background .25s;

  &:hover,
  &:focus {
    background-color: $slider-handle-hover-bg;
    outline: none;
  }
  &:active {
    background-color: $slider-handle-active-bg;
  }
}

.ui-slider-range {
  background-color: $slider-range-bg;
  display: block;
  height: 100%;
  position: absolute;
  z-index: 1;
}

// Segments
// -------------------------
.ui-slider-segment {
  background-color: $slider-segment-bg;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}

// Values
// -------------------------
.ui-slider-value {
  float: right;
  font-size: $slider-value-font-size;
  margin-top: $slider-height;

  &.first {
    clear: left;
    float: left;
  }
}

// Horizontal orientation
// -------------------------

.ui-slider-horizontal {
  .ui-slider-handle {
    margin-left: -9px;
    top: -3px;

    &[style*="100"] {
      margin-left: -15px;
    }
  }
  .ui-slider-range {
    border-radius: 30px 0 0 30px;
  }
  .ui-slider-segment {
    float: left;
    margin: 3px -6px 0 0;
  }
}

// Vertical orientation
// -------------------------

.ui-slider-vertical {
  width: $slider-height;

  .ui-slider-handle {
    margin-left: -3px;
    margin-bottom: -11px;
    top: auto;
  }
  .ui-slider-range {
    width: 100%;
    bottom: 0;
    border-radius: 0 0 30px 30px;
  }
  .ui-slider-segment {
    position: absolute;
    right: 3px;
  }
}
