//
// Tags Input
// --------------------------------------------------

.bootstrap-tagsinput {
  background-color: $tagsinput-container-bg;
  border: 2px solid $tagsinput-container-border-color;
  border-radius: $tagsinput-container-border-radius;
  margin-bottom: 18px;
  padding: 6px 1px 1px 6px;
  text-align: left;
  font-size: 0;

  .badge {
    border-radius: $tagsinput-tag-border-radius;
    background-color: $tagsinput-tag-bg;
    color: $tagsinput-tag-color;
    font-size: floor(($component-font-size-base * 0.886)); // ~13px
    cursor: pointer;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    margin: 0 5px 5px 0;
    line-height: 15px;
    height: 27px;
    padding: 6px 28px 6px 14px;
    transition: .25s linear;

    > span {
      color: $tagsinput-tag-icon-color;
      padding: 0 10px 0 0;
      cursor: pointer;
      font-size: 12px;
      position: absolute;
      right: 0;
      text-align: right;
      text-decoration: none;
      top: 0;
      width: 100%;
      bottom: 0;
      z-index: 2;

      &:after {
        content: "\e609";
        font-family: "Flat-UI-Pro-Icons";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 27px;
      }
    }

    @media (hover: hover) {

      padding: 6px 21px;

      > span {
        @include opacity(0);
        transition: opacity .25s linear;
      }

      &:hover {
        background-color: $tagsinput-tag-hover-bg;
        color: $tagsinput-tag-hover-color;
        padding-right: 28px;
        padding-left: 14px;

        > span {
          padding: 0 10px 0 0;
          opacity: 1;
          filter: none;
        }
      }

    }

    @media (hover: none) {

      &:hover {
        background-color: $tagsinput-tag-hover-bg;
        color: $tagsinput-tag-hover-color;
      }

    }
  }

  input[type="text"] {
    font-size: ceil(($component-font-size-base * 0.933)); // 14px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    min-width: 80px;
    vertical-align: top;
    height: 29px;
    color: $tagsinput-input-color;

    &:first-child {
      height: 23px;
      margin: 3px 0 8px;
    }
  }
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0;
}
.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
  margin-left: 5px !important;
}

// Alternate Color
// --------------------------------------------------
.tagsinput-primary {
  margin-bottom: 18px;

  .bootstrap-tagsinput {
    border-color: $tagsinput-primary-container-border-color;
    margin-bottom: 0;
  }
  .badge {
    background-color: $tagsinput-primary-tag-bg;
    color: $tagsinput-primary-tag-color;

    &:hover {
      background-color: $tagsinput-primary-tag-hover-bg;
      color: $tagsinput-primary-tag-hover-color;
    }
  }
}
// Styles for Typeahead support
// --------------------------------------------------
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
  vertical-align: top;

  .tt-input {
    min-width: 200px;
  }
  .tt-dropdown-menu {
    width: auto;
    min-width: 120px;
    margin-top: 11px;
  }
}
