//
// Code (inline and blocK)
// --------------------------------------------------


// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

// Inline code
code {
  padding: 2px 6px;
  font-size: 85%;
  color: $code-color;
  background-color: $code-bg;
  border-radius: $border-radius-base;
}

// User input typically entered via keyboard
kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: $kbd-color;
  background-color: $kbd-bg;
  border-radius: $border-radius-base;
  box-shadow: none;
}

// Blocks of code
pre {
  padding: (($line-height-computed - 6) / 3);
  margin: 0 0 ($line-height-computed / 2);
  font-size: ($font-size-base - 5); // 18px to 13px
  line-height: $line-height-base;
  color: $pre-color;
  background-color: $pre-bg;
  border: 2px solid $pre-border-color;
  border-radius: $pre-border-radius;
  white-space: pre;
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
}