//
// Footer
// --------------------------------------------------

footer {
  background-color: mix($brand-primary, $inverse, 9%);
  color: mix($brand-primary, $inverse, 34%);
  font-size: 15px;
  padding: 0;

  a {
    color: mix($brand-primary, $inverse, 50%);
    font-weight: 700;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.footer-title {
  margin: 0 0 22px;
  padding-top: 21px;
  font-size: 24px;
  line-height: 40px;
}

.footer-brand {
  display: block;
  margin-bottom: 26px;
  width: 220px;

  img {
    width: 216px;
  }
}

// Footer banner
.footer-banner {
  background-color: $brand-secondary;
  color: mix($brand-secondary, $inverse, 20%);
  margin-left: 42px;
  min-height: 316px;
  padding: 0 30px 30px;

  .footer-title {
    color: $inverse;
  }
  a {
    color: lighten($brand-secondary, 42%);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  ul {
    list-style-type: none;
    margin: 0 0 26px;
    padding: 0;

    li {
      border-top: 1px solid lighten($brand-secondary, 2%);
      line-height: 19px;
      padding: 6px 0;

      &:first-child {
        border-top: none;
        padding-top: 1px;
      }
    }
  }
}
