//
//  Login screen
// --------------------------------------------------

// Module color variable
$form-color: mix($brand-primary, $inverse, 9%);

.login {
  background: url(../images/login/imac.png) 0 0 no-repeat;
  background-size: 940px 778px;
  color: $inverse;
  margin-bottom: 77px;
  padding: 38px 38px 267px;
  position: relative;
}

.login-screen {
  background-color: $brand-secondary;
  min-height: 473px;
  padding: 123px 199px 33px 306px;
}

.login-icon {
  left: 200px;
  position: absolute;
  top: 160px;
  width: 96px;

  > img {
    display: block;
    margin-bottom: 6px;
    width: 100%;
  }
  > h4 {
    font-size: 17px;
    font-weight: 300;
    line-height: 34px;
    opacity: .95;

    small {
      color: inherit;
      display: block;
      font-size: inherit;
      font-weight: 700;
    }
  }
}

// LOGIN FORM
// -----------
.login-form {
  background-color: $form-color;
  padding: 24px 23px 20px;
  position: relative;
  border-radius: $border-radius-large;

  .control-group {
    margin-bottom: 6px;
    position: relative;
  }
  .login-field {
    border-color: transparent;
    font-size: 17px;
    text-indent: 3px;

    &:focus {
      border-color: $brand-secondary;

      & + .login-field-icon {
        color: $brand-secondary;
      }
    }
  }
  .login-field-icon {
    color: mix($gray, $inverse, 60%);
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 3px;
    transition: all .25s;
  }
}

.login-link {
  color: mix($gray, $inverse, 60%);
  display: block;
  font-size: 13px;
  margin-top: 15px;
  text-align: center;
}

// Retina support
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
  .login {
    background-image: url(../images/login/imac-2x.png);
  }
}
