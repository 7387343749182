// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: #e5e5e5) {
  height: 2px;
  margin: 3px 0;
  overflow: hidden;
  background-color: $color;
}
