//
// Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name-black}.eot');
  src: url('#{$local-font-path}#{$local-font-name-black}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name-black}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name-black}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name-black}.svg##{$local-font-svg-id-black}') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name-bold}.eot');
  src: url('#{$local-font-path}#{$local-font-name-bold}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name-bold}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name-bold}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name-bold}.svg##{$local-font-svg-id-bold}') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name-bold-italic}.eot');
  src: url('#{$local-font-path}#{$local-font-name-bold-italic}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name-bold-italic}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name-bold-italic}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name-bold-italic}.svg##{$local-font-svg-id-bold-italic}') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name-italic}.eot');
  src: url('#{$local-font-path}#{$local-font-name-italic}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name-italic}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name-italic}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name-italic}.svg##{$local-font-svg-id-italic}') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name-light}.eot');
  src: url('#{$local-font-path}#{$local-font-name-light}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name-light}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name-light}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name-light}.svg##{$local-font-svg-id-light}') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$local-font-path}#{$local-font-name}.eot');
  src: url('#{$local-font-path}#{$local-font-name}.eot?#iefix') format('embedded-opentype'),
       url('#{$local-font-path}#{$local-font-name}.woff') format('woff'),
       url('#{$local-font-path}#{$local-font-name}.ttf') format('truetype'),
       url('#{$local-font-path}#{$local-font-name}.svg##{$local-font-svg-id}') format('svg');
  font-weight: normal;
  font-style: normal;
}
