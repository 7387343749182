//
// Scaffolding
// --------------------------------------------------

* {
  outline: none !important;
}

// Body reset
// -------------------------

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
}

// Links
// -------------------------

a {
  color: $link-color;
  text-decoration: none;
  transition: .25s;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &:focus {
    outline: none;
  }
}

// Images
// -------------------------

img {
  max-width: 100%;
}

// Rounded corners
.img-rounded {
  border-radius: $border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.scss for `.thumbnail`.
.img-thumbnail {
  padding: $thumbnail-padding;
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  border: 2px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  transition: all .25s ease-in-out;

  // Keep them at most 100% wide
  @include img-responsive(inline-block);
}

// Description text under image
.img-comment {
  font-size: ceil(($font-size-base * 0.8333)); // ~15px
  line-height: 1.2;
  font-style: italic;
  margin: 24px 0;
}
