//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: $thumbnail-padding;
  margin-bottom: 5px;
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  border: 2px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  transition: border .25s ease-in-out;

  > img,
  a > img {
    @include img-responsive();
    margin-left: auto;
    margin-right: auto;
  }

  // Add a hover state for linked versions only
  a {
    &:hover,
    &:focus,
    &.active {
      border-color: $link-color;
    }
  }

  // Image captions
  .caption {
    padding: $thumbnail-caption-padding;
    color: $thumbnail-caption-color;
  }
}
