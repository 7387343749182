//
// Checkbox & Radio
// --------------------------------------------------

.checkbox,
.radio {
  display: block;
  margin-top: 10px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  transition: color .25s linear;
  font-size: ceil(($component-font-size-base * 0.933)); // ~14px
  min-height: 20px;
  line-height: 1.5; // 21px;

  .icons {
    color: $gray-light;
    display: block;
    height: 20px;
    top: 0;
    left: 0;
    position: absolute;
    width: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    cursor: pointer;
    transition: color .25s linear;

    .icon-checked {
      @include opacity(0);
    }
  }
}

.checkbox,
.radio {
  .icon-checked,
  .icon-unchecked {
    display: inline-table;
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    margin: 0;
    opacity: 1;
    filter: none;

    &:before {
      font-family: 'Flat-UI-Pro-Icons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
.checkbox {
  .icon-checked:before {
    content: "\e60e";
  }
  .icon-unchecked:before {
    content: "\e60d";
  }
}
.radio {
  .icon-checked:before {
    content: "\e60c";
  }
  .icon-unchecked:before {
    content: "\e60b";
  }
}

.checkbox,
.radio {
  input[type="checkbox"].custom-checkbox,
  input[type="radio"].custom-radio {
    outline: none !important;
    opacity: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;


    // Alternate States
    // --------------------------------------------------

    &:hover:not(.nohover) + .icons {
      transition: color .25s linear;

      .icon-unchecked {
        @include opacity(0);
      }
      .icon-checked {
        opacity: 1;
        filter: none;
      }
    }

    &:checked + .icons {
      color: $brand-secondary;

      .icon-unchecked {
        @include opacity(0);
      }
      .icon-checked {
        opacity: 1;
        filter: none;
        color: $brand-secondary;
       transition: color .25s linear;
      }
    }

    &:disabled + .icons {
      cursor: default;
      color: mix($gray-light, white, 38%);

      .icons {
        color: mix($gray-light, white, 38%);
      }
      .icon-unchecked {
        opacity: 1;
        filter: none;
      }
      .icon-checked {
        @include opacity(0);
      }
    }

    &:disabled:checked + .icons {
      .icons {
        color: mix($gray-light, white, 38%);
      }
      .icon-unchecked {
        @include opacity(0);
      }
      .icon-checked {
        opacity: 1;
        filter: none;
        color: mix($gray-light, white, 38%);
      }
    }

    &:indeterminate + .icons {
      color: $gray-light;

      .icon-unchecked {
        opacity: 1;
        filter: none;
      }
      .icon-checked {
        @include opacity(0);
      }
      &:before {
        content: "\2013";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 20px;
        width: 20px;
        text-align: center;
        color: $inverse;
        font-size: 22px;
        z-index: 10;
      }
    }

    &:focus + .icons {
        outline: 1px dotted $gray-light;
        outline-offset: 1px;
    }
  }


  // Alternate Color
  // --------------------------------------------------

  // Primary

  &.primary input[type="checkbox"].custom-checkbox,
  &.primary input[type="radio"].custom-radio {
    & + .icons {
      color: $brand-primary;
    }
    // Checked State
    &:checked + .icons {
      color: $brand-secondary;

      .icons {
        color: $brand-secondary;
      }
    }
    // Disabled state
    &:disabled + .icons {
      cursor: default;
      color: $gray-light;

      .icons {
        color: $gray-light;
      }
      &.checked {
        .icons {
          color: $gray-light;
        }
      }
    }

    &:indeterminate + .icons {
      color: $brand-primary;
    }

  }
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}

// Form inline style

.form-inline .checkbox, .form-inline .radio {
  padding-left: 32px;
}
